import * as React from "react";
import { motion, useAnimation } from "framer-motion";
import styled from "styled-components";

const BurgerWrapper = styled(motion.span)`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  position: relative;
  width: 36px;
  height: 36px;
  cursor: pointer;
`;

const BurgerBar = styled(motion.span)`
  position: relative;
  background-color: ${({ darkMode, theme }) =>
    darkMode ? theme.COLORS.snow : theme.COLORS.brandVermilion};
  height: 6px;
  border-radius: 8px;
  margin: 2px;
  width: ${({ width }) => (width ? width : "32px")};
`;

const topBarVariants = {
  open: { rotate: -45, width: 28, x: -6 },
  closed: { rotate: 0, width: 24, x: 0 },
};

const middleBarVariants = {
  open: { width: 0, opacity: 0 },
  closed: { width: 32, opacity: 1 },
};

const bottomBarVariants = {
  open: { rotate: 45, width: 28, x: -6 },
  closed: { rotate: 0, width: 18, x: 0 },
};

const BurgerMenu = ({
  darkMode = false,
  isOpen = false,
  onClick = () => {},
}) => {
  const topBar = useAnimation();
  const middleBar = useAnimation();
  const bottomBar = useAnimation();

  React.useEffect(() => {
    if (isOpen) {
      topBar.start("open");
      middleBar.start("open");
      bottomBar.start("open");
      return;
    }

    topBar.start("closed");
    middleBar.start("closed");
    bottomBar.start("closed");
  }, [isOpen, topBar, middleBar, bottomBar]);

  return (
    <BurgerWrapper isOpen={isOpen} onClick={onClick}>
      <BurgerBar
        variants={topBarVariants}
        iniital="closed"
        animate={topBar}
        width="24px"
        style={{ originX: "100%" }}
        darkMode={darkMode}
      />
      <BurgerBar
        variants={middleBarVariants}
        iniital="closed"
        animate={middleBar}
        darkMode={darkMode}
      />
      <BurgerBar
        variants={bottomBarVariants}
        iniital="closed"
        animate={bottomBar}
        width="18px"
        style={{ originX: "100%" }}
        darkMode={darkMode}
      />
    </BurgerWrapper>
  );
};

export default BurgerMenu;
