import * as React from "react";
import TransitionLink from "gatsby-plugin-transition-link";
import {
  motion,
  useViewportScroll,
  useTransform,
  useAnimation,
  AnimatePresence,
} from "framer-motion";
import styled from "styled-components";
import BurgerMenu from "../controls/BurgerMenu";
import LogoSimple from "../../assets/images/svg/logo_simple_text.inline.svg";
import LogoSimpleLight from "../../assets/images/svg/logo_simple_text_light.inline.svg";

const NAVS = [
  {
    name: "Home",
    route: "/",
  },
  {
    name: "About",
    route: "/about",
  },
  {
    name: "Services",
    route: "/services",
  },
  {
    name: "Team",
    route: "/team",
  },
];

const VANav = styled(motion.nav)`
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 0;
  width: 100%;
  background-color: ${({ isOpen, darkMode, theme }) => {
    if (isOpen) {
      return darkMode
        ? `${theme.COLORS.abyss} !important`
        : `${theme.COLORS.snow} !important`;
    }
    return "unset";
  }};
  z-index: 1000;
`;

const TopRow = styled(motion.div)`
  height: 135px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: 0 20px;
  z-index: 120;
  position: relative;
  background-color: ${({ isOpen, darkMode, theme }) => {
    if (isOpen) {
      return darkMode
        ? `${theme.COLORS.abyss} !important`
        : `${theme.COLORS.snow} !important`;
    }
    return "unset";
  }};
`;

const TopContainer = styled(motion.div)`
  flex: 1;
  display: flex;
  align-items: center;
`;

const NavDisplay = styled(motion.div)`
  top: 135px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100vh - 135px);
  position: absolute;
  background-color: ${({ theme, darkMode }) =>
    darkMode ? theme.COLORS.abyss : theme.COLORS.snow};
  z-index: 50;
`;

const NavItems = styled(motion.ul)`
  font-family: ${({ theme }) => theme.FONTS.family1};
  font-weight: 600;
  font-size: ${({ theme }) => theme.FONTS.sizes.default};
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: ${({ theme, darkMode }) =>
      darkMode ? theme.COLORS.snow : theme.COLORS.darkest};
  }

  a:hover {
    background-color: ${({ theme }) => theme.COLORS.vermilion};
    color: ${({ theme }) => theme.COLORS.snow};
  }

  li {
    padding: 14px 26px;
  }
`;

const NavContentVariants = {
  open: {
    opacity: 1,
    x: 0,
  },
  closed: {
    x: "100%",
    opacity: 0,
    transition: {
      x: { type: "spring", stiffness: 100, duration: 1 },
    },
  },
};

const getColorRange = (isDarkMode) => {
  if (isDarkMode) {
    return ["rgba(0, 0, 0, 0)", "rgba(70, 74, 80, 1)"];
  }
  return ["rgba(255, 255, 255, 0)", "rgba(246, 248, 249, 1)"];
};

const Nav = ({ path }) => {
  const [darkMode, setDarkMode] = React.useState(true);
  const [isOpen, setIsOpen] = React.useState(false);
  const navAnimation = useAnimation();
  const navMenuAnimation = useAnimation();
  const { scrollY } = useViewportScroll();
  const lastScroll = React.useRef(0);

  const scrollRange = [0, 135];
  const colorRange = getColorRange(darkMode);
  const shadowRange = [
    "0 1px 4px  rgba(21, 28, 37, 0)",
    "0 1px 4px  rgba(21, 28, 37, 0.15)",
  ];

  React.useEffect(() => {
    if (typeof window === "undefined") return;
    console.log("did render client side", window.location.pathname);
    if (window.location.pathname.includes("services")) setDarkMode(true);
  }, []);

  const [, setNavImageIsVisible] = React.useState(false);
  React.useEffect(() => {
    const updateScrollListeners = () => {
      const currentScroll = scrollY.get();
      setNavImageIsVisible(currentScroll > 135);
      if (currentScroll > lastScroll.current) {
        // is going down
        if (currentScroll > 405) {
          navAnimation.start({
            y: -135,
          });
        }
      } else {
        // is going up
        navAnimation.start({
          y: 0,
          transition: {
            ease: "easeOut",
          },
        });
      }

      lastScroll.current = scrollY.get();
    };

    const unsub = scrollY.onChange(updateScrollListeners);
    return () => {
      unsub();
    };
  }, [scrollY, navAnimation]);

  const bgColor = useTransform(scrollY, scrollRange, colorRange);
  const shadow = useTransform(scrollY, scrollRange, shadowRange);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const closeNav = () => {
    setIsOpen(false);
  };

  React.useEffect(() => {
    setDarkMode(path === "/" || path.includes("services"));
  }, [path]);

  React.useEffect(() => {
    navMenuAnimation.start(isOpen ? "open" : "closed");
  }, [isOpen, navMenuAnimation]);

  const navStyle = {
    backgroundColor: !isOpen ? bgColor : undefined,
    boxShadow: shadow,
  };

  return (
    <VANav
      layout
      animate={navAnimation}
      path={path}
      isOpen={isOpen}
      darkMode={darkMode}
      style={navStyle}
    >
      <TopRow darkMode={darkMode} isOpen={isOpen}>
        <TopContainer />
        <TopContainer style={{ justifyContent: "center", maxHeight: "135px" }}>
          <TransitionLink to="/">
            <AnimatePresence>
              {darkMode && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{
                    opacity: path !== "/" || scrollY.get() > 135 ? 1 : 0,
                  }}
                  exit={{ opacity: 0 }}
                >
                  <LogoSimpleLight
                    key="logosimplelight"
                    style={{
                      width: "72px",
                      maxHeight: "135px",
                    }}
                  />
                </motion.div>
              )}
              {!darkMode && (
                <motion.div
                  key="logosimple"
                  initial={{ opacity: 0 }}
                  animate={{
                    opacity: path !== "/" ? 1 : 0,
                  }}
                  exit={{ opacity: 0 }}
                >
                  <LogoSimple
                    style={{
                      width: "72px",
                      maxHeight: "135px",
                    }}
                  />
                </motion.div>
              )}
            </AnimatePresence>
          </TransitionLink>
        </TopContainer>
        <TopContainer layout style={{ justifyContent: "flex-end" }}>
          <BurgerMenu
            darkMode={darkMode}
            isOpen={isOpen}
            onClick={toggleOpen}
          />
        </TopContainer>
      </TopRow>
      <NavDisplay
        animate={navMenuAnimation}
        variants={NavContentVariants}
        initial={{ x: "100%" }}
        darkMode={darkMode}
      >
        <NavItems layout darkMode={darkMode}>
          {NAVS.map((n, i) => {
            return (
              <TransitionLink
                onClick={closeNav}
                key={i}
                to={n.route}
                entry={{
                  length: 1,
                  delay: 1,
                }}
                exit={{
                  length: 1,
                  delay: 0,
                }}
              >
                <li>{n.name}</li>
              </TransitionLink>
            );
          })}
        </NavItems>
      </NavDisplay>
    </VANav>
  );
};

export default Nav;
