export default {
  COLORS: {
    vermilion: "#d44057",
    marine: "#7ebed1",
    casino: "#306071",
    abyss: "#464a50",
    darkest: "#333",
    darker: "#71797D",
    snow: "#f6f8f9",
    silverfox: "#e8ecee",
    rainyday: "#cbd4db",
    brandDark: "#221f20",
    brandVermilion: "#722a29",
  },
  FONTS: {
    family1: "Montserrat, Proxima Nova, Helvatica, Arial, sans-serif",
    family2: "Calibri, sans-serif",
    sizes: {
      xxlarge: "6.4rem",
      xlarge: "3.4rem",
      large: "2.0rem",
      default: "1.7rem",
      small: "1.3rem",
      xsmall: "1.0rem",
    },
  },
};
