import * as React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import LogoFullLight from "../../assets/images/svg/logo_full_light.inline.svg";
import ProviderLogo from "../../assets/images/svg/hs_logo_v3_with_text_light.inline.svg";
import FooterContent from "../../content/footer.json";

const FooterContainer = styled(motion.footer)`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.COLORS.abyss};
  padding: 20px;
`;

const LogoWrapper = styled(motion.div)`
  width: clamp(128px, 100%, 160px);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FooterDetailsWrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const FooterDetail = styled(motion.span)`
  font-size: ${({ theme }) => theme.FONTS.sizes.xsmall};
  color: ${({ theme }) => theme.COLORS.snow};
`;

const Footer = () => {
  return (
    <FooterContainer>
      <LogoWrapper>
        <LogoFullLight
          style={{
            width: "clamp(128px, 100%, 160px)",
            height: "clamp(128px, 100%, 160px)",
          }}
        />
      </LogoWrapper>
      <FooterDetailsWrapper>
        {/* <FooterDetail as="h5">Registered Office</FooterDetail>
        <FooterDetail>{FooterContent.addressTitle}</FooterDetail>
        <FooterDetail>{FooterContent.addressLine1}</FooterDetail>
        <FooterDetail>{FooterContent.addressLine2}</FooterDetail>
        <FooterDetail>{FooterContent.city}</FooterDetail>
        <FooterDetail>{FooterContent.country}</FooterDetail> */}
        <a
          href={`mailto: ${FooterContent.contactEmail}`}
          style={{ textDecoration: "none" }}
        >
          <FooterDetail>
            {FooterContent.contact_us} {FooterContent.contactEmail}
          </FooterDetail>
        </a>
        {/* <a
          href={"https://hazel.software"}
          style={{ margin: "24px 0", textDecoration: "none" }}
        >
          <FooterDetail
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            Site by
            <ProviderLogo width="85px" height="32px" />
          </FooterDetail>
        </a> */}
      </FooterDetailsWrapper>
    </FooterContainer>
  );
};

export default Footer;
