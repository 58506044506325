import * as React from "react";
import Nav from "../layout/Nav";
import Footer from "../layout/Footer";
import styled, { ThemeProvider } from "styled-components";
import VATheme from "../../styles/theme";
import { motion } from "framer-motion";

const ContentContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const UniLayout = ({ children, location }) => {
  return (
    <ThemeProvider theme={VATheme}>
      <Nav path={location.pathname} />
      <ContentContainer>{children}</ContentContainer>
      <Footer />
    </ThemeProvider>
  );
};

export default UniLayout;
